<template>
  <v-form @submit.prevent="list(true)">
    <v-container>
      <v-row>
        <v-col :cols="12">
          <span class="text-h5"> Mudanças de assinaturas </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12">
          <v-card>
            <v-card-text>
              <Paginator
                v-bind.sync="subscriptionChangeList.pagingOptions"
                @page="pageSelected"
              ></Paginator>

              <v-data-table
                :headers="subscriptionChangeList.headers"
                :items="subscriptionChangeList.data"
                :items-per-page="25"
                :disable-sort="true"
                :loading="subscriptionChangeList.loading"
                no-data-text=""
                loading-text="Carregando dados..."
                class="app-table"
                hide-default-footer
              >
                <template v-slot:[`item.errors`]="{ item }">
                  <SubscriberRegistrationWarning
                    :subscriber="item"
                  ></SubscriberRegistrationWarning>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                  <div>
                    <span class="list-item-title"
                      >{{ item.first_name }} {{ item.last_name }}
                    </span>
                    <br />
                    <span class="list-item-subtitle">{{ item.cpf }}</span>
                  </div>
                </template>
                <template v-slot:[`item.subscription_plan`]="{ item }">
                  {{ item.subscription_plan_name }}
                </template>

                <template v-slot:[`item.event_date`]="{ item }">
                  {{ item.event_date | defaultDate("DD/MM/YYYY HH:mm") }}
                </template>

                <template v-slot:[`item.subscription_history`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-btn color="primary" icon>
                          <v-icon>mdi-clipboard-text-outline</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span
                      >{{ item.a }}
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th>Data da alteração</th>
                            <th>Plano</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in item.history"
                            :key="index"
                          >
                            <td>{{ item.event_date | defaultDate('DD/MM/YYYY HH:mm') }}</td>
                            <td>{{ item.subscription_plan_name }}</td>
                            <td>
                              <v-chip
                                small
                                :color="
                                  item.status
                                    | enum(
                                      data.subscriptionPlanStatus,
                                      'id',
                                      'class'
                                    )
                                "
                                dark
                                >{{
                                  item.status
                                    | enum(data.subscriptionPlanStatus)
                                }}</v-chip
                              >
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { eventHub } from "@/main";
import subscriptionApi from "@/common/api/subscription.api";
import clientApi from "@/common/api/client.api";
import Paginator from "@/components/layout/Paginator";
import SubscriberRegistrationWarning from "@/components/subscriber/SubscriberRegistrationWarning.vue";
import subscriptionPlanStatus from "@/common/data/subscription-plan-status.data";

export default {
  name: "SubscriptionChangesList",

  components: { Paginator, SubscriberRegistrationWarning },

  data: () => ({
    data: {
      subscriptionPlanStatus: subscriptionPlanStatus,
    },
    subscriptionChangeList: {
      filter: {},
      headers: [
        {
          text: "",
          value: "errors",
          class: "icon-cell",
          cellClass: "icon-cell",
        },
        { text: "Nome", value: "name" },
        {
          text: "Plano",
          value: "subscription_plan",
        },
        {
          text: "Data do evento",
          value: "event_date",
        },
        {
          text: "Histórico",
          value: "subscription_history",
        },
      ],
      data: [],
      loading: false,
      pagingOptions: {
        currentPage: 1,
      },
    },
  }),

  beforeDestroy() {
    eventHub.$off("SELECTED_CLIENT");
  },

  mounted() {
    eventHub.$on("SELECTED_CLIENT", () => {
      this.list(true);
    });
  },

  created() {
    this.list();
  },

  methods: {
    pageSelected(page) {
      this.subscriptionChangeList.pagingOptions.currentPage = page;
      this.list();
    },

    list(resetPage) {
      if (resetPage) {
        this.subscriptionChangeList.pagingOptions.currentPage = 1;
      }

      let client = clientApi.getSelectedClient().id;

      if (client) {
        this.subscriptionChangeList.filter.providerId = client;
        this.subscriptionChangeList.filter.page =
          this.subscriptionChangeList.pagingOptions.currentPage;

        this.subscriptionChangeList.loading = true;
        subscriptionApi
          .listChanges(this.subscriptionChangeList.filter)
          .then((result) => {
            this.subscriptionChangeList.loading = false;
            this.subscriptionChangeList.data = result.data.data;
            this.subscriptionChangeList.pagingOptions =
              result.data.pagingOptions;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.icon-cell {
  padding-right: 0px !important;

  & + td,
  & + th {
    padding-left: 8px !important;
  }
}
</style>