var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.list(true)}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('span',{staticClass:"text-h5"},[_vm._v(" Mudanças de assinaturas ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-card',[_c('v-card-text',[_c('Paginator',_vm._b({on:{"page":_vm.pageSelected}},'Paginator',_vm.subscriptionChangeList.pagingOptions,false,true)),_c('v-data-table',{staticClass:"app-table",attrs:{"headers":_vm.subscriptionChangeList.headers,"items":_vm.subscriptionChangeList.data,"items-per-page":25,"disable-sort":true,"loading":_vm.subscriptionChangeList.loading,"no-data-text":"","loading-text":"Carregando dados...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.errors",fn:function(ref){
var item = ref.item;
return [_c('SubscriberRegistrationWarning',{attrs:{"subscriber":item}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"list-item-title"},[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]),_c('br'),_c('span',{staticClass:"list-item-subtitle"},[_vm._v(_vm._s(item.cpf))])])]}},{key:"item.subscription_plan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subscription_plan_name)+" ")]}},{key:"item.event_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("defaultDate")(item.event_date,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.subscription_history",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"color":"primary","icon":""}},[_c('v-icon',[_vm._v("mdi-clipboard-text-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.a)+" "),_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Data da alteração")]),_c('th',[_vm._v("Plano")]),_c('th',[_vm._v("Status")])])]),_c('tbody',_vm._l((item.history),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm._f("defaultDate")(item.event_date,'DD/MM/YYYY HH:mm')))]),_c('td',[_vm._v(_vm._s(item.subscription_plan_name))]),_c('td',[_c('v-chip',{attrs:{"small":"","color":_vm._f("enum")(item.status,
                                    _vm.data.subscriptionPlanStatus,
                                    'id',
                                    'class'
                                  ),"dark":""}},[_vm._v(_vm._s(_vm._f("enum")(item.status,_vm.data.subscriptionPlanStatus)))])],1)])}),0)])],1)])]}}],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }