<template>
  <v-tooltip bottom v-if="getErrors()">
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="yellow darken-2" v-bind="attrs" v-on="on"
        >mdi-alert</v-icon
      >
    </template>
    <span v-html="getErrors()"></span>
  </v-tooltip>
</template>

<script>
export default {
  name: "SubscriberRegistrationWarning",

  props: ["subscriber"],

  methods: {
    getErrors() {
      let warnings = [];

      if (!this.subscriber.cpf) {
        warnings.push("CPF não preenchido");
      }

      if (
        !this.subscriber.zip_code ||
        !this.subscriber.street ||
        !this.subscriber.district ||
        !this.subscriber.city ||
        !this.subscriber.state
      ) {
        warnings.push("Endereço incompleto");
      }

      if (!this.subscriber.payment_day) {
        warnings.push("Dia de vencimento não selecionado");
      }

      if (((this.subscriber.first_name &&
          this.subscriber.first_name.toUpperCase().indexOf("TESTE") >= 0) ||
          (this.subscriber.last_name &&
            this.subscriber.last_name.toUpperCase().indexOf("TESTE") >= 0)) &&
        !this.subscriber.test_user
      ) {
        warnings.push(
          "O assinante não deveria estar marcado como um usuário de teste?"
        );
      }

      return warnings.join("<br />");
    },
  },
};
</script>